// require('@fortawesome/fontawesome-free/css/all.min.css');
// require('@fortawesome/fontawesome-free/js/all.min.js');

import Splide from '@splidejs/splide';


let mainNavLinks = document.querySelectorAll("nav.home ul li a");
let animating = false;
let scrollEvent = true;

var banner = document.getElementById('splide');
if (null != banner) {
    new Splide('#splide', {
        type: 'loop',
        height: '100%',
        cover: true,
        autoplay: true,
        breakpoints: {
            640: {
                height: '10rem',
                perPage: 1
            }
        }
    }).mount();
}

window.addEventListener("scroll", event => {
    let fromTop = window.scrollY;

    mainNavLinks.forEach(link => {
        if (link.hash !== "") {
            let section = document.querySelector(link.hash);
            let navbar = document.querySelector('#navbar ul');

            if (
                section.offsetTop <= fromTop &&
                section.offsetTop + section.offsetHeight > fromTop
            ) {
                link.classList.add("current");
                if (scrollEvent) {
                    scrollTo(navbar, link.offsetLeft, 1250);
                }
            } else {
                link.classList.remove("current");
            }
        }
    });
});

window.onscroll = function () {
    if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky")
    } else {
        navbar.classList.remove("sticky");
    }
};

let navbar = document.getElementById("navbar");
let sticky = navbar.offsetTop;

function detectMob() {
    return ((window.innerWidth <= 600) && (window.innerHeight <= 800));
}

window.scrollPage = function (hash) {
    if (detectMob()) {
        scrollEvent = false;
        let page = document.querySelector('html');
        let element = document.querySelector(hash);

        page.scrollTop = element.offsetTop;
        setTimeout(function() {
            scrollEvent = true;
            scrollTo(navbar, element.offsetLeft, 1250);
        }, 1500);
        return false;
    }
};

function scrollTo(element, to, duration) {
    if (animating) {
        return;
    }
    animating = true;
    var start = element.scrollLeft,
        change = to - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function () {
        currentTime += increment;
        element.scrollLeft = Math.easeInOutQuad(currentTime, start, change, duration);
        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        } else {
            animating = false;
        }
    };
    animateScroll();
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};
